<template>
  <div class="overflow-x-auto">
    <table class="w-full border-collapse text-gray-800">
      <thead>
        <tr>
          <th
            v-for="(column, i) in columns"
            :key="i"
            class="uppercase font-normal text-gray-500 text-left text-xs pb-2 px-6"
          >
            {{ column }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(exam, key) in candidate.sentExams"
          :key="key"
          class="h-20"
        >
          <TableCell >
            <p>{{ exam.name }}</p>
            <p
              v-if="exam.level"
              class="text-xs text-gray-500 uppercase tracking-wide"
            >
              {{ exam.level }}
            </p>
          </TableCell>
          <TableCell>
            <p v-if="exam.sentAt">
              {{ formatDate(exam.sentAt) }}
            </p>
          </TableCell>
          <TableCell>
            <p v-if="exam.completedAt">
              {{ formatDate(exam.completedAt) }}
            </p>
            <p v-else-if="exam.started">
              Incomplete
              <BaseButton
                size="small"
                variant="white"
                class="ml-2"
                :disabled="resetInProgress"
                @click="resetExam(exam)"
              >
                Reset
              </BaseButton>
            </p>
            <p v-else>
              Sent
            </p>
          </TableCell>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableCell from '@components/TableCell'
import { formatDate } from '@utils/formatDate'

import organisationCandidatesApi from '@api/organisationCandidates'

import { mapGetters } from 'vuex'

export default {
  components: {
    TableCell
  },

  props: {
    candidate: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      formatDate,
      columns: ['Test', 'Sent', 'Completed'],

      resetInProgress: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  methods: {
    /**
     * Reset the exam so it can be taken again
     *
     * @param {Object} exam
     */
    resetExam(exam) {
      if (this.resetInProgress) {
        return
      }

      // To prevent overlapping requests for the same candidate, we lock all
      // the buttons when one is in progress
      this.resetInProgress = true

      organisationCandidatesApi.resetExam(
        this.organisationId,
        this.candidate.id,
        exam.slug,
        exam.sittingId
      )
        .then(() => {
          this.resetInProgress = false
          exam.started = false
        })
        .catch(() => {
          this.resetInProgress = false
        })
    }
  }
}
</script>
